body {
  height: 100vh;
  background: #282c34;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.search {
  width: 80%;
  margin: 14px 0px;
}
.search input {  
  padding: 10px;  
  border-radius: 14px;
  font-size: 1em;
  border: none;
  outline: none;
}

.search button {  
  padding: 10px;  
  border-radius: 4px;
  font-size: 0.8em;
  border: none;
  outline: none;
  margin-left: 8px;
}

.actions {
  display: flex;
}

.actions button {
  margin: 4px;
  padding: 8px 14px;
  border: none;
  border-radius: 2px;
}

.analytics {
  font-size: 0.7em;
}

table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #aaa;
}

th, td {
  padding: 20px;
}

th {
  letter-spacing: 2px;
}

td {
  letter-spacing: 1px;
}

tbody td {
  text-align: center;
}

/* tbody tr:nth-child(even) {
  background-color: #888888;
} */

td.name {

  text-transform: capitalize;
}

.first-row {
  background-color: #303944;
}

.total, .grand-total{
  font-weight: bold;
  font-size: 1.25em;
  border: 1px solid #aaa;
}

.grand-total {
background-color: #3a4858;
}

button.btn-process {
  background: #00adb5;
  color: #fff;
}

input[type="file"] {
  visibility: hidden;
}

.error {
      color: #ffffff;
    padding: 10px;
    background: #d57272;
    margin: 10px 0px;
    border-radius: 4px;
}
 
.processing {
      color: #ffffff;
    padding: 10px;
    background: #72d572;
    margin: 10px 0px;
    border-radius: 4px;
}